/*
 * @Author: wangliang
 * @Date: 2023-04-20 15:14:18
 * @LastEditTime: 2024-10-11 14:23:33
 * @LastEditors: wangliang
 * @Description: 系统信息
 */
export const systemName = "银杏大模型";
export const systemNameBottom =
	`版权所有 © 杭州叙简科技股份有限公司 `;

export const systemInfo = [
	{
		systemName: '银杏大模型',
		icon: 'favicon.ico',
		logoUrl: require('@/assets/images/logo.png'),
		loginLogoUrl: require('@/assets/images/login/icon_logo.png'),
		toolBoxName: '银杏工具箱',
		assistantInfo: {
			avatar: require('@/assets/images/chat/icon_yinxing.png'),
			code: '',
			name: '银杏智能助手',
			title: '您好，我是银杏城市安全问答助手',
			description: '作为知识导航助手，对于用户提问进行精准全面的信息回应，为用户提供信息查询、专业咨询、疑问解答、辅助创意构思等多场景语言服务。',
			prompt: '作为您的智能伙伴，任何关于城市安全与应急方面的问题，都可以问我。',
			isCarryHistory: 0
		},
	},
	{
		systemName: '智能助手小应',
		icon: 'yu_favicon.ico',
		logoUrl: require('@/assets/images/yu_logo.png'),
		loginLogoUrl: require('@/assets/images/login/icon_yu_logo.png'),
		toolBoxName: '小应工具箱',
		assistantInfo: {
			avatar: require('@/assets/images/chat/icon_yinxing.png'),
			code: '',
			name: '小应',
			title: '您好，我是小应城市安全问答助手',
			description: '作为知识导航助手，对于用户提问进行精准全面的信息回应，为用户提供信息查询、专业咨询、疑问解答、辅助创意构思等多场景语言服务。',
			prompt: '作为您的智能伙伴，任何关于城市安全与应急方面的问题，都可以问我。',
			isCarryHistory: 0
		},
	},
]

